import { createSlice } from '@reduxjs/toolkit';
import { httpClient } from './../../../src/utils/HttpClient';
import { server } from './../../../src/constants';
import Swal from 'sweetalert2';
const initialState = {
    isLoading: false,
    error: false,
    requirementData: [],
    topic: {},
    lastEdit:{},
    alternativesList:[],
    alternativesListFormAPI:[],
    alternativesAuth:{},
    commentRequirement:[],
    keywordList:[],
    filterLeyList:[],
    selectTap:0,
    isOpenKeywordDialog: false,
    checkStatusComment: {},
};

const slice = createSlice({
    name: 'requrement',
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },
        stopLoading(state) {
            state.isLoading = false;
        },
        setStatusComment(state, { payload }) {
            const { reqId, status } = payload;
            state.checkStatusComment[reqId] = status;
        },
        hasError(state) {
            state.isLoading = false;
            state.error = true;
        },
        getRequirementSuccess(state, action) {
            state.isLoading = false;
            state.requirementData = action.payload.requirements;
            state.topic = action.payload.topic;
            state.lastEdit = action.payload.last_update;
            state.checkStatusComment = {};
        },
        addAlternativesSuccess(state, action) {
            state.alternativesList = action.payload;
        },
        saveRequirementsSuccess(state) {
            state.isLoading = false;
        },
        insertRequirementSuccess(state) {
            state.isLoading = false;
        },
        getRequirementCommentSuccess(state, action) {
            state.commentRequirement = action.payload.results;
        },
        setSelectTap(state, {payload}){
            state.selectTap = payload;
        },
        getAlternativeCommentSuccess(state, action) {
            state.isLoading = false;
            const {cover_authorize, results} = action.payload;
            state.alternativesListFormAPI = results;
            state.alternativesAuth = cover_authorize;
        },
        setOpenKeywordDialog(state, action){
            state.isOpenKeywordDialog = action.payload;
        },
        setGetkeywordsComplete(state, action){
            state.isLoading = false;
            state.keywordList = action.payload;
        },
        setChangeFilterKey(state, action){
            state.filterLeyList = action.payload;
        }
    },
});

export default slice.reducer;

export const {
    startLoading,
    hasError,
    getRequirementSuccess,
    saveRequirementsSuccess,
    addAlternativesSuccess,
    insertRequirementSuccess,
    getAlternativeCommentSuccess,
    getRequirementCommentSuccess,
    setSelectTap,
    setOpenKeywordDialog,
    setGetkeywordsComplete,
    setChangeFilterKey,
    stopLoading,
    setStatusComment
} = slice.actions;

export const getRequirement = (
    id_project,
    id_stage,
    id_tab,
    id_topic = null
) => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());
            const response = await httpClient.get(
                `${
                    server.REQUIREMENT_URL
                }?id_project=${id_project}&id_stage=${id_stage}&id_tab=${id_tab}${
                    id_topic != null ? `&id_topic=${id_topic}` : ''
                }`
            );
            if (response.status === 200) {
                dispatch(getRequirementSuccess({requirements:response.data.requirements, topic: response.data.topic, last_update: response.data.last_update}));
            }
        } catch (error) {
            dispatch(hasError(error));
        }
    };
};

export const addAlternativesTolist = (list) => {
    return async (dispatch) => {
        try {
            dispatch(addAlternativesSuccess(list));
        } catch (error) {
            dispatch(hasError(error));
        }
    };
};

export const addKeywordFilterTolist = (list) => {
    return async (dispatch) => {
        try {
            dispatch(setChangeFilterKey(list));
        } catch (error) {
            dispatch(hasError(error));
        }
    };
};

export const changeSelectTap = (selected) => {
    return async (dispatch) => {
        try {
            dispatch(setSelectTap(selected));
        } catch (error) {
            dispatch(hasError(error));
        }
    };
};

export const insertComment = (data, uploadedFiles) => {
    return async (dispatch) => {
        try {
            let formData = new FormData();
            formData.append('id_stage', data.id_stage);
            formData.append('id_role', data.from_role);
            formData.append('id_form_requirement', data.id_form_requirement);
            formData.append('comment', data.comment);
            if (uploadedFiles.length > 0) {
                for (const element of uploadedFiles) {
                    const row = element;
                    formData.append('uploadedFiles', row);
                }
            }

  
            const response = await httpClient.post(server.REQUIREMENT_COMMENT_URL, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Save data successfully'
                });
                dispatch(setStatusComment({reqId: data.id_form_requirement, status: true}));
                dispatch(insertRequirementSuccess());
                dispatch(getRequirementComment(data.id_form_requirement, data.id_stage, data.id_project));
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                title: 'Save data error'
            });
            dispatch(hasError(error));
        }
    };
};

export const insertAlternativeComment = (data) => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());
            const response = await httpClient.post(server.ALTERNATIVE_COMMENT_URL,data);
            if (response.status === 200) {
                dispatch(stopLoading());
                Toast.fire({
                    icon: 'success',
                    title: 'Save data successfully'
                });
            }
        } catch (error) {
            dispatch(hasError(error));
        }
    };
};

export const getKeywordList = () => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());
            const response = await httpClient.get(server.KEYWORD_URL);
            if (response.status === 200) {
                const keywordList = response.data.data;
                let keys = [];
                for (const element of keywordList) {
                    const row = element;
                    if(!keys.includes(row.keyword)){
                        keys.push(row.keyword);
                    }
                }

                dispatch(setGetkeywordsComplete(keys));
            }
        } catch (error) {
            dispatch(hasError(error));
        }
    };
};

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
});

export const getAlternativeComment = (projectId) => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());
            const response = await httpClient.get(server.ALTERNATIVE_COMMENT_URL + `?id_project=${projectId}`);
            if (response.status === 200) {
                dispatch(getAlternativeCommentSuccess(response.data));
            }
        } catch (error) {
            dispatch(hasError(error));
        }
    };
};
export const getRequirementComment = (reqId, stage, id_project) => {
    return async (dispatch) => {
        try {
            const response = await httpClient.get(server.REQUIREMENT_COMMENT_URL + `?id_form_requirement=${reqId}&id_stage=${stage}&id_project=${id_project}`);
            if (response.status === 200) {
                dispatch(getRequirementCommentSuccess(response.data));
            }
        } catch (error) {
            dispatch(hasError(error));
        }
    };
};

export const saveRequirements = (
    dataPayLoad,
    keysChange,
    dataForSave,
    dataOlds,
    changeTap,
) => {
    return async (dispatch) => {
        try {
            if (keysChange.length > 0) {
                dispatch(startLoading());
                let dataForAPISave = [];
                for (const element of keysChange) {
                    const keyChange = element;
                    if (
                        JSON.stringify(dataForSave[keyChange]) !==
            JSON.stringify(dataOlds[keyChange])
                    ) {
                        dataForAPISave.push(dataForSave[keyChange]);
                    }
                }
                if (dataForAPISave.length !== 0) {
                    dataPayLoad.requirements = dataForAPISave;
                    const response = await httpClient.patch(
                        `${server.REQUIREMENT_URL}`,
                        dataPayLoad
                    );
                    if (response.status === 200) {
                        dispatch(saveRequirementsSuccess(response.data));
                        dispatch(changeSelectTap(changeTap));
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'Your work has been saved',
                            showConfirmButton: false,
                            timer: 1500
                        });
                        dispatch(
                            getRequirement(
                                dataPayLoad.id_project,
                                dataPayLoad.id_stage,
                                dataPayLoad.id_tab,
                                dataPayLoad.id_topic
                            )
                        );
                    }
                }
            }
        } catch (error) {
            dispatch(hasError(error));
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: error.response.data.errors[0].message,
                showConfirmButton: false,
            });
        }
    };
};
