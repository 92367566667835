import { PATH_APP } from './paths';
import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthProtect from '../components/guard/authProtect';

const AppRoutes = {
    path: PATH_APP.root,
    routes: [
        {
            guard: AuthProtect,
            exact: true,
            path: PATH_APP.app.home,
            component: lazy(() => import('../pages/Home'))
        },
        {
            guard: AuthProtect,
            exact: true,
            path: PATH_APP.app.overview,
            component: lazy(() => import('../pages/Overview'))
        },
        {
            guard: AuthProtect,
            exact: true,
            path: PATH_APP.app.scorecard,
            component: lazy(() => import('../pages/Scorecard'))
        },
        {
            guard: AuthProtect,
            exact: true,
            path: PATH_APP.app.dashboard,
            component: lazy(() => import('../pages/Dashboard'))
        },
        {
            guard: AuthProtect,
            exact: true,
            path: PATH_APP.app.searchResult,
            component: lazy(() => import('../pages/SearchResult'))
        },
        {
            guard: AuthProtect,
            exact: true,
            path: PATH_APP.app.construction,
            component: lazy(() => import('../pages/Construction'))
        },
        {
            guard: AuthProtect,
            exact: true,
            path: PATH_APP.app.survey,
            component: lazy(() => import('../pages/Survey&Design'))
        },
        // ----------------------------------------------------------------------
        {
            component: () => <Navigate to="/404" />
        }
    ]
};

export default AppRoutes;
