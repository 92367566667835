import { createSlice } from '@reduxjs/toolkit';
import { httpClient } from './../../../src/utils/HttpClient';
import { server } from './../../../src/constants';

export const dashboard = createSlice({
    name: 'dashboard',
    initialState: {
        dashboardList: [],
        stateList:[],
        isLoading: false,
        error: false,
    },
    reducers: {
        setDashboardList: (state, action) => {
            state.dashboardList = action.payload;
            state.isLoading = false;
        },
        setStateList: (state, action) => {
            state.stateList = action.payload;
        },
        hasError(state) {
            state.isLoading = false;
            state.error = true;
        },
        startLoading(state) {
            state.isLoading = true;
        },
    },
});

export default dashboard.reducer;
export const { setDashboardList, hasError, isLoading, setStateList, startLoading} = dashboard.actions;

export const getDashboard = (projectId, stageId) => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());
            const {data} = await httpClient.get(server.DASHBOARD + `?projectId=${projectId}&stageId=${stageId}`);
            dispatch(setDashboardList(data.result));
            dispatch(setStateList(data.footer));
        } catch (error) {
            dispatch(hasError(error));
        }
    };
};

