import { PATH_PAGE } from '../routes/paths';
import AppRoutes from './AppRoutes';
import { Route } from 'react-router-dom';
import GuestProtect from '../components/guard/guestProtect';
import React, { Fragment, lazy } from 'react';

export function renderRoutes(routesList = []) {
    return routesList.map((route, i) => {
        const Component = route.component;
        const Guard = route.guard || Fragment;
        const Child = (props) => (
            <Guard>{!route.routes && <Component {...props} />}</Guard>
        );

        if (route.path) {
            return (
                <React.Fragment key={i}>
                    <Route
            
                        path={route.path}
                        exact={route.exact}
                        element={<Child />}
                    ></Route>
                    {route.routes && renderRoutes(route.routes)}
                </React.Fragment>
            );
        }
    });
}

const routes = [
    {
        exact: true,
        path: '/',
        guard: GuestProtect,
        component: lazy(() => import('../pages/Login')),
    },
    {
        exact: true,
        guard: GuestProtect,
        path: PATH_PAGE.auth.login,
        component: lazy(() => import('../pages/Login')),
    },
    {
        exact: true,
        path: '*',
        component: lazy(() => import('../pages/404')),
    },
    AppRoutes,
];

export default routes;
