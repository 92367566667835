import axios from 'axios';
const axiosApiInstance = axios.create();
import join from 'url-join';
import {
    server,
    apiUrl,
} from './../../src/constants';

const isAbsoluteURLRegex = /^(?:\w+:)\/\//;

axiosApiInstance.interceptors.request.use(async (config) => {
    const userToken = localStorage.getItem(server.TOKEN_KEY);
    if (userToken) {
        config.headers = { Authorization: `Bearer ${userToken}` };
    }
    config.headers['Content-Type'] = 'application/json';
    config.headers['Accept'] = 'application/json';

    if (!isAbsoluteURLRegex.test(config.url)) {
        config.url = join(apiUrl, config.url);
        if(config.method==='get') {
            if(config.url.includes('/items/projects') || config.url.includes('/users/me')){
                config.headers['Cache-Control'] = 'no-store';
            }
        }
    }
    return config;
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

axiosApiInstance.interceptors.response.use((response) => {
    return response;
}, async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
            return new Promise(function (resolve, reject) {
                failedQueue.push({ resolve, reject });
            }).then(token => {
                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                return axios(originalRequest);
            }).catch(err => {
                return Promise.reject(err);
            });
        }
        isRefreshing = true;
        originalRequest._retry = true;

        return new Promise(function (resolve, reject) {
            const refreshToken = localStorage.getItem(server.REFRESH_TOKEN_KEY);
            const refreshUrl = `${apiUrl}/${server.REFRESH_TOKEN_URL}`;
            axios.post(refreshUrl, { refresh_token:refreshToken }).then((result)=>{
                if (result.status === 200) {
                    const { access_token, refresh_token } = result.data.data;
                    localStorage.setItem(server.TOKEN_KEY, access_token);
                    localStorage.setItem(server.REFRESH_TOKEN_KEY, refresh_token);
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token; 
                    processQueue(null, access_token);
                    resolve(axiosApiInstance(originalRequest));
                } else{
                    localStorage.removeItem(server.TOKEN_KEY);
                    localStorage.removeItem(server.REFRESH_TOKEN_KEY);
                    window.location.href = '/?showError=1';
                    reject(error);
                }
            }).catch((err) => {
                localStorage.removeItem(server.TOKEN_KEY);
                localStorage.removeItem(server.REFRESH_TOKEN_KEY);
                window.location.href = '/?showError=1';
                reject(err);
            });
        });
    }
    return Promise.reject(error);
});

export const httpClient = axiosApiInstance;
