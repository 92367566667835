function path(root, sublink) {
    return `${root}${sublink}`;
}

const ROOTS = {
    auth: '/auth',
    app: '/app',
    main:'/'
};

export const PATH_PAGE = {
    auth: {
        root: ROOTS.auth,
        login: path(ROOTS.auth, '/login'),
        resetPassword: path(ROOTS.auth, '/reset-password'),
    }
};

export const PATH_HOME = {
    cloud: 'https://www.sketch.com/s/0fa4699d-a3ff-4cd5-a3a7-d851eb7e17f0',
    purchase: 'https://material-ui.com/store/items/minimal-dashboard/',
    dashboard: ROOTS.app
};

export const PATH_APP = {
    main: ROOTS.main,
    root: ROOTS.app,
    app: {
        home: path(ROOTS.app, '/home'),
        overview: path(ROOTS.app,'/overview'),
        scorecard: path(ROOTS.app,'/scorecard'),
        searchResult: path(ROOTS.app,'/searchResult'),
        dashboard: path(ROOTS.app,'/dashboard'),
        construction: path(ROOTS.app,'/construction'),
        survey: path(ROOTS.app,'/survey'),
    }
};
