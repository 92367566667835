import { createSlice } from '@reduxjs/toolkit';
import { httpClient } from './../../../src/utils/HttpClient';
import { server } from './../../../src/constants';
import { PATH_APP, PATH_PAGE } from '../../routes/paths';
import { setSelectProject, setProject } from './../../redux/reducer/projectSlice';
import Swal from 'sweetalert2';

const initialState = {
    isLoading: false,
    error: false,
    errorData: null,
    isInvalid: false,
    isAuthenticated: false,
    auth: [],
    isReset: false,
};

const slice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },
        stopLoading(state) {
            state.isLoading = false;
        },
        setResetPassword(state, action) {
            state.isReset = action.payload;
        },
        hasError(state) {
            state.isLoading = false;
            state.error = true;
        },
        hasInvalid(state, action) {
            state.isLoading = false;
            state.isInvalid = action.payload;
        },
        getAuthSuccess(state, action) {
            state.isLoading = false;
            state.isInvalid = false;
            state.isAuthenticated = true;
            state.auth = action.payload;
        },
        logout(state) {
            state.isLoading = false;
            state.error = true;
        },
        setAuthData(state) {
            state.isLoading = false;
        },
    },
});

export default slice.reducer;

export const { startLoading, stopLoading, hasError, getAuthSuccess, logout, hasInvalid, setResetPassword } =
  slice.actions;

export const login = ({ username, password }, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());
            const response = await httpClient.post(server.LOGIN_URL, {
                email: username,
                password,
            });
            if (response.status === 200) {
                const { access_token, refresh_token } = response.data.data;

                localStorage.setItem(server.TOKEN_KEY, access_token);
                localStorage.setItem(server.REFRESH_TOKEN_KEY, refresh_token);

                const currentUser = await httpClient.get(
                    server.CURRENT_USER_URL
                );

                if (currentUser.status === 200) {
                    localStorage.setItem(
                        server.AUTH_DATA,
                        JSON.stringify(currentUser.data.data)
                    );
                    dispatch(
                        getAuthSuccess({ username, password, access_token, refresh_token })
                    );
                    navigate(PATH_APP.app.home);
                }
            } else {
                dispatch(hasError());
            }
        } catch (error) {
            dispatch(hasInvalid(true));
        }
    };
};

export const isLoggedIn = () => {
    return !!(localStorage.getItem(server.TOKEN_KEY) ||
    localStorage.getItem(server.REFRESH_TOKEN_KEY));
};

export const setIsinvalidToFalse = () => {
    return (dispatch) => {
        dispatch(hasInvalid(false));
    };
};

export const loginAD = (refresh_token, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const response = await httpClient.post(server.LOGIN_AD, {
                refresh_token: refresh_token,
            });
            if (response.status === 200) {
                const { access_token, refresh_token } = response.data.data;

                localStorage.setItem(server.TOKEN_KEY, access_token);
                localStorage.setItem(server.REFRESH_TOKEN_KEY, refresh_token);

                const currentUser = await httpClient.get(server.CURRENT_USER_URL);

                if (currentUser.status === 200) {
                    localStorage.setItem(
                        server.AUTH_DATA,
                        JSON.stringify(currentUser.data.data)
                    );
                    dispatch(getAuthSuccess({ access_token, refresh_token }));
                    navigate(PATH_APP.app.home);
                }
            } else {
                dispatch(hasError());
            }
        } catch (error) {
            dispatch(hasInvalid(true));
        }
    };
};

export const isLogout = (navigate) => {
    return async (dispatch) => {
        localStorage.clear();
        dispatch(setSelectProject(null));
        dispatch(setProject([]));
        navigate(PATH_PAGE.auth.login);
        dispatch(logout());
    };
};

export const requestPassword = ({ username }) => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());
            const res = await httpClient.post(server.CHECK_PASSWORD_URL, {
                email: username,
            });
            if (res.status === 200) {
                const response = await httpClient.post(server.REQUEST_PASSWORD_URL, {
                    email: username,
                    reset_url: `${process.env.REACT_APP_APP_URL}/reset-password`
                });
                if (response.status === 204) {
                    dispatch(stopLoading());
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Your work has been saved',
                        showConfirmButton: false,
                        timer: 1500
                    });
                    setTimeout(() => {
                        dispatch(setResetPassword(false));
                    }, 2000);
                } else {
                    dispatch(hasError());
                }
            } else {
                dispatch(stopLoading());
                Swal.fire({
                    position: 'top-end',
                    icon: 'warning',
                    title: res.data.message,
                    showConfirmButton: false,
                });
            }
        } catch (error) {
            dispatch(hasInvalid(true));
            if (error.response.status == 422) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'warning',
                    title: 'Your password cannot be reset at this site.<br/><a href="https://resetpass.dtgo.com/RDWeb/Pages/en-US/password.aspx">Please click Here</a> to reset your password.',
                    showConfirmButton: false,
                });
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'warning',
                    title: error.response.data.message,
                    showConfirmButton: false,
                });
            }
            
        }
    };
};

export const resetPassword = ({ password,token },navigate) => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());
            const response = await httpClient.post(server.RESET_PASSWORD_URL, {
                token:token,
                password: password,
            });
            if (response.status === 204) {
                dispatch(stopLoading());
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500
                });
                setTimeout(() => {
                    navigate(PATH_PAGE.auth.login);
                }, 2000);

            } else {
                dispatch(hasError());
            }
        } catch (error) {
            dispatch(hasInvalid(true));
        }
    };
};