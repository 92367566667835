import { configureStore } from '@reduxjs/toolkit';
import activeTabReducer from './reducer/activeTabSlice';
import drawerReducer from './reducer/drawer';
import changeLangReducer from './reducer/changeLang';
import authReducer from './reducer/auth';
import projectReducer from './reducer/projectSlice';
import requrementReducer from './reducer/requrementSlice';
import searchReducer from './reducer/searchSlice';
import scoreCardReducer from './reducer/scoreCardSlice';
import dashboardReducer from './reducer/dashboardSlice';


export const store = configureStore({
    reducer: {
        activeTab: activeTabReducer,
        drawer: drawerReducer,
        changeLang: changeLangReducer,
        auth: authReducer,
        project: projectReducer,
        requrement: requrementReducer,
        search: searchReducer,
        scoreCard: scoreCardReducer,
        dashboard: dashboardReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }),
    
});
