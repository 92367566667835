import { createSlice } from '@reduxjs/toolkit';

export const drawerSlice = createSlice({
    name: 'drawer',
    initialState: {
        drawer: false,
    },
    reducers: {
        setComplete: (state) => {
            state.drawer = !state.drawer;
        },
    },
});

export default drawerSlice.reducer;

export const { setComplete } = drawerSlice.actions;

export const openDrawer =
  () =>
      async (dispatch) => {
          dispatch(setComplete());
      };
