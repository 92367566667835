export const server = {
    TOKEN_KEY: 'token',
    AUTH_DATA: 'auth_data',
    REFRESH_TOKEN_KEY: 'refresh_token',
    REFRESH_TOKEN_URL: 'auth/refresh',
    LOGIN_URL: '/auth/login',
    CHECK_PASSWORD_URL: '/user/reset-password',
    REQUEST_PASSWORD_URL: '/auth/password/request',
    RESET_PASSWORD_URL: '/auth/password/reset',
    LOGIN_AD: '/auth/refresh',
    CURRENT_USER_URL: '/users/me',
    PROJECT_URL: '/items/projects?fields=id,name_en,name_th,id_project_form.stage.*',
    COMMENT_URL: '/project/cover',
    TOPIC_URL: '/project/topic',
    TAB_URL: '/project/tab',
    TOPIC_TAB_URL: '/project/topic/tab',
    REQUIREMENT_URL: '/project/requirement',
    SEARCH_PROJECT_URL: '/project/search-requirement',
    SEARCH_PROJECT_COUNT_URL: '/project/search-counting',
    HOME_CORVER:'/home/cover',
    REQUIREMENT_COMMENT_URL:'/project/comment',
    ALTERNATIVE_COMMENT_URL:'/project/alternative-comment',
    KEYWORD_URL: '/items/standard_requirement_keyword',
    POPULAR_KEYWORD_URL: '/project/popular-secret-key',
    KEYWORD_OPTION:'/project/keyword-options',
    SCORECARD_STATE: '/scorecard/stage',
    SCORECARD_TOPIC: '/scorecard/topics',
    SCORECARD_TOPIC_DETAIL: '/scorecard/topics-detail',
    DASHBOARD: '/dashboard',
};

export const NETWORK_CONNECTION_MESSAGE =
  'Cannot connect to server, Please try again.';
export const NETWORK_TIMEOUT_MESSAGE =
  'A network timeout has occurred, Please try again.';
export const NOT_CONNECT_NETWORK = 'NOT_CONNECT_NETWORK';

export const apiUrl = process.env.REACT_APP_API_URL;




const getAppUrl = () => {
    let url = process.env.REACT_APP_APP_URL;
    if(process.env.NODE_ENV != 'production'){
        url = 'http://localhost:3000/';
    }
    return url;
};

export const appUrl = getAppUrl();