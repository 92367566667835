import { createSlice } from '@reduxjs/toolkit';
import i18n from 'i18next';

export const changeLang = createSlice({
    name: 'changeLang',
    initialState: {
        lang: 'en',
    },
    reducers: {
        setNewLang: (state, action) => {
            state.lang = action.payload;
            i18n.changeLanguage(state.lang);
        },
    },
});

export default changeLang.reducer;
export const { setNewLang } = changeLang.actions;

export const changeLangState =
  ({ lang }) =>
      async (dispatch) => {
          dispatch(setNewLang(lang));
      };
