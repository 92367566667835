import React from 'react';
import PropTypes from 'prop-types';
import * as useAuth from '../../redux/reducer/auth';
import { Navigate } from 'react-router-dom';
import { PATH_APP } from '../../routes/paths';
import { server } from '../../constants';
// ----------------------------------------------------------------------

GuestProtect.propTypes = {
    children: PropTypes.node
};

function GuestProtect({ children }) {

    if (useAuth.isLoggedIn()) {
        if(window.location.href.includes('reset-password')){
            localStorage.removeItem(server.TOKEN_KEY);
            localStorage.removeItem(server.REFRESH_TOKEN_KEY);
            let url = '/'+window.location.href.split('/').at(-1);
            return window.location.href = url;
        }
        return <Navigate to={PATH_APP.app.home} />;
    }
    return <>{children}</>;
}

export default GuestProtect;
