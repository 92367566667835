import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import routes, { renderRoutes } from './routes';
import ReactLoading from 'react-loading';

const history = createBrowserHistory();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <BrowserRouter location={history.location} navigator={history}>
            <React.Suspense
                fallback={
                    <div
                        className="container-fluid justify-content-center align-content-center d-flex flex-wrap"
                        style={{ height: 900 }}
                    >
                        <ReactLoading
                            type={'spin'}
                            color={'#1A4B92'}
                            height={'10%'}
                            width={'10%'}
                        />
                    </div>
                }
            >
                <Routes>
                    <Route path="/" element={<App />}>
                        {renderRoutes(routes)}
                    </Route>
                </Routes>
            </React.Suspense>
        </BrowserRouter>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
