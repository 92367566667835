import { httpClient } from './../../../src/utils/HttpClient';
import { server } from './../../../src/constants';
import { createSlice } from '@reduxjs/toolkit';

export const searchSlice = createSlice({
    name: 'search',
    initialState: {
        searchKeywords: [],
        dataSearch:null,
        dataSearchCount:null,
        isLoading: false,
        error: false,
        showFilter: false,
        filterLeyList: [],
        popularKeys:[],
        countChilds:[],
        optionList:[],
        keywordByOptionList:[],
        currentCount:0,
        sumCount:0,
    },  
    reducers: {
        setSearchKeywords: (state, action) => {
            state.searchKeywords = action.payload;
        },
        getSearchSucess: (state, action) => {
            state.dataSearch = action.payload;
            state.isLoading = false;
        },
        getSearchCountSucess: (state, action) => {
            state.dataSearchCount = action.payload;
        },
        startLoading(state) {
            state.isLoading = true;
        },
        setShowFilter(state, action) {
            state.showFilter = action.payload;
        },
        hasError(state) {
            state.isLoading = false;
            state.error = true;
        },
        setChangeFilterKey(state, action){
            state.filterLeyList = action.payload;
        },
        setPopularKeys(state, action){
            state.popularKeys = action.payload;
        },
        setCountChilds(state, action){
            state.countChilds = action.payload;
            state.isLoading = false;
        },
        setCurrentCount(state, action){
            state.currentCount = action.payload;
        },
        setSumCurrentCount(state, action){
            state.sumCount = action.payload;
        },
        getKeywordsAndOptionSuccess(state, action){
            state.optionList = action.payload.options;
            state.keywordByOptionList = action.payload.keywords;
        }
    },
});

export default searchSlice.reducer;

export const { setSearchKeywords, setCountChilds, startLoading, hasError, getSearchSucess, getSearchCountSucess, setShowFilter, setChangeFilterKey, setPopularKeys, getKeywordsAndOptionSuccess, setCurrentCount, setSumCurrentCount } = searchSlice.actions;

export const searchBykeyWord = (searchParams) => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());
            const response = await httpClient.get(
                `${
                    server.SEARCH_PROJECT_URL
                }${checkParam(searchParams)}`
            );
            if (response.status === 200) {
                dispatch(getSearchSucess(response.data));
            }
        } catch (error) {
            dispatch(hasError(error));
        }
    };
};

export const getKeywordsAndOption = (projectId) => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());
            const response = await httpClient.get(
                `${
                    server.KEYWORD_OPTION
                }?projectId=${projectId}`
            );
            if (response.status === 200) {
                dispatch(getKeywordsAndOptionSuccess(response.data));
            }
        } catch (error) {
            dispatch(hasError(error));
        }
    };
};

const checkParam = (searchParams) => {
    let query = new URLSearchParams();
    let param = '?';
    let isAll = searchParams.get('all');
    let projectId = searchParams.get('id_project');
    let topicId = searchParams.get('id_topic');
    let categoryId = searchParams.get('categoryId');
    let keywords = searchParams.get('keywords');
    let tagKey = searchParams.get('tagKey');
    let OptionKey = searchParams.get('OptionsKey');
    query.append('projectId', projectId);
    
    if(keywords){
        query.append('keywords', keywords);
    }
    if(tagKey){
        query.append('tagKey', tagKey);
    }
    if(OptionKey){
        query.append('OptionsKey', OptionKey);
    }
    if (isAll == 1) {
        return param + query.toString();
    }
    if(topicId){
        query.append('categoryId', topicId);
    }else{
        query.append('categoryId', categoryId);
    }
    
    return param + query.toString();
};


export const searchBykeyWordCount = (searchParams) => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());
            const response = await httpClient.get(`${server.SEARCH_PROJECT_COUNT_URL}${checkParam(searchParams)}`);
            if (response.status === 200) {
                let datares = [...response.data];
                if (searchParams.get('categoryId') != null) {
                    const dataFind = datares.find((item)=> item.id.toString() === searchParams.get('categoryId'));
                    if(dataFind){
                        dispatch(setCountChilds(dataFind.childs));
                    }
                } else {
                    let tmp = [];
                    for (const element of datares) {
                        const row = element;
                        tmp = [...tmp, ...row.childs];
                    }
                    dispatch(setCountChilds(tmp));
                }
                const sum = datares.reduce((accumulator, object) => (accumulator + object.count), 0);
                dispatch(setSumCurrentCount(sum));
                dispatch(getSearchCountSucess(response.data));
            }
        } catch (error) {
            dispatch(hasError(error));
        }
    };
};

export const getPopularKeys = () => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());
            const response = await httpClient.get(server.POPULAR_KEYWORD_URL);
            if (response.status === 200) {
                dispatch(setPopularKeys(response.data));
            }
        } catch (error) {
            dispatch(hasError(error));
        }
    };
};


