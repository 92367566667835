import { createSlice } from '@reduxjs/toolkit';
import { httpClient } from './../../../src/utils/HttpClient';
import { server } from './../../../src/constants';
import Swal from 'sweetalert2';


const initialState = {
    isLoading: false,
    error: false,
    projectList: [],
    currentProject: null,
    comentsData: [],
    topicData: [],
    commentRole: {},
    currentTab: 1,
    currentTopic: '',
    tabList: [],
    topicTabList: [],
    cateWithTopicLists: [],
    headerNameTH: '',
    headerNameEN: '',
};

const slice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },
        hasError(state) {
            state.isLoading = false;
            state.error = true;
        },
        setProject(state, action) {
            state.isLoading = false;
            state.projectList = action.payload;
        },
        setCurrentProject(state, action) {
            state.currentProject = action.payload;
        },
        getCommentSuccess(state, action) {
            state.isLoading = false;
            state.comentsData = action.payload.comentsData;
            state.commentRole = action.payload.commentRole;
        },
        getTopicSuccess(state, action) {
            state.isLoading = false;
            state.topicData = action.payload;
        },
        getCurrentTabSuccess(state, action) {
            state.isLoading = false;
            state.currentTab = action.payload;
        },
        getCurrentTopicSuccess(state, action) {
            state.isLoading = false;
            state.currentTopic = action.payload;
        },
        getTabListSuccess(state, action) {
            state.isLoading = false;
            state.tabList = action.payload;
        },
        getTopicTabSuccess(state, action) {
            state.isLoading = false;
            state.topicTabList = action.payload;
        },
        getCateWithTopicSuccess(state, action) {
            state.isLoading = false;
            state.cateWithTopicLists = action.payload;
        },
        getHeaderNameTHSuccess(state, action) {
            state.isLoading = false;
            state.headerNameTH = action.payload;
        },
        getHeaderNameENSuccess(state, action) {
            state.isLoading = false;
            state.headerNameEN = action.payload;
        },
    },
});

export default slice.reducer;

export const {
    startLoading,
    hasError,
    setProject,
    setCurrentProject,
    getCommentSuccess,
    getTopicSuccess,
    getCurrentTabSuccess,
    getTabListSuccess,
    getCurrentTopicSuccess,
    getCateWithTopicSuccess,
    getTopicTabSuccess,
    getHeaderNameTHSuccess,
    getHeaderNameENSuccess,
} = slice.actions;

export const getProject = (projectiId = 0) => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());
            const response = await httpClient.get(server.PROJECT_URL);
            if (response.status === 200) {
                dispatch(setProject(response.data.data));
                if(projectiId !== 0){
                    const projectData  = response.data.data;
                    for (const element of projectData) {
                        const row = element;
                        if(row.id === projectiId){
                            dispatch(setSelectProject(row));
                        }
                    }
                }
            }
        } catch (error) {
            dispatch(hasError(error));
        }
    };
};

export const setSelectProject = (project) => {
    return async (dispatch) => {
        try {
            dispatch(setCurrentProject(project));
        } catch (error) {
            dispatch(hasError(error));
        }
    };
};

export const getCommentByProjectId = (projectId, stateId) => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());
            const response = await httpClient.get(
                server.COMMENT_URL + `?id_project=${projectId}&id_stage=${stateId}`
            );

            if (response.status === 200) {
                const { data } = response;
                dispatch(
                    getCommentSuccess({
                        comentsData: data.cover_comments,
                        commentRole: data.cover_authorize,
                    })
                );
            }
        } catch (error) {
            dispatch(hasError(error));
        }
    };
};

export const getTopicByProjectId = (projectId) => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());
            const response = await httpClient.get(
                server.TOPIC_URL + `?id_project=${projectId}`
            );
            if (response.status === 200) {
                dispatch(getTopicSuccess(response.data));
            }
        } catch (error) {
            dispatch(hasError(error));
        }
    };
};

export const insertComment = (
    projectId,
    comment,
    from_stage,
    from_role,
    decline_flag,
    uploadedFiles
) => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());
            let formData = new FormData();
            let json = {
                id_project: projectId,
                comment,
                from_stage,
                from_role,
                decline_flag,
            };
            formData.append('item', JSON.stringify(json));
            if (uploadedFiles.length > 0) {
                for (const element of uploadedFiles) {
                    const row = element;
                    formData.append('uploadedFiles', row);
                }
            }

            const response = await httpClient.post(server.HOME_CORVER, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.status === 200 || response.status === 201) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500
                });
                dispatch(getCommentByProjectId(projectId, response.data.from_stage));
                dispatch(getProject());
            }
        } catch (error) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Your not work has been saved',
                showConfirmButton: false,
                timer: 1500
            });
            dispatch(hasError(error));
        }
    };
};

export const getCurrentTab = (projectId,currentTab = 1) => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());
            const response = await httpClient.get(
                server.TAB_URL + `?id_project=${projectId}`
            );
            const {data} = response;
            if (response.status === 200) {
                let tabList = data.find((item)=>item.default_flag);
                if(tabList) {
                    const getTopic = await httpClient.get(
                        server.TOPIC_TAB_URL +`?id_project=${projectId}&id_tab=${currentTab}`
                    );
                    if (getTopic.status === 200) {
                        dispatch(checkDataTopic(getTopic));
                        dispatch(getTopicTabSuccess(getTopic.data));
                    }
                }
                dispatch(getCateWithTopicSuccess(response.data));
                dispatch(getCurrentTabSuccess(currentTab));
            }
        } catch (error) {
            dispatch(hasError(error));
        }
    };
};

export const checkDataTopic = (topicData) => {
    return async (dispatch) => {
        if (topicData.data && topicData.data.length > 0) {
            if (
                topicData.data[0].childs &&
                topicData.data[0].childs.length > 0
            ) {
                dispatch(
                    getCurrentTopicSuccess(topicData.data[0].childs[0].id)
                );
            } else {
                dispatch(getCurrentTopicSuccess(0));
            }
        }
    };
};

export const getCateWithTopicId = (projectId) => {
    return async (dispatch) => {
        try {
            const response = await httpClient.get(
                server.TAB_URL + `?id_project=${projectId}`
            );
            if (response.status === 200) {
                dispatch(getCateWithTopicSuccess(response.data));
            }
        } catch (error) {
            dispatch(hasError(error));
        }
    };
};


export const getActiveTapForRediect = (id_tab,projectId, navigate, link) => {
    return async (dispatch) => {
        dispatch(startLoading());
        const {data, status} = await httpClient.get(
            server.TOPIC_TAB_URL + `?id_project=${projectId}&id_tab=${id_tab}`
        );
        if (status === 200) {
            dispatch(getTopicTabSuccess(data));
            localStorage.setItem('id_tab', id_tab);
            if(data){
                if(data[0]){
                    if(data[0].childs && data[0].childs.length > 0){
                        navigate(link + `&id_topic=${data[0].childs[0].id}`);
                        localStorage.setItem('id_topic', data[0].childs[0].id);
                        return;
                    }
                }
            }
            localStorage.removeItem('id_topic');
            navigate(link);
        }
    };
};



