import { httpClient } from './../../../src/utils/HttpClient';
import { server } from './../../../src/constants';
import { createSlice } from '@reduxjs/toolkit';

export const scoreCardSlice = createSlice({
    name: 'scoreCard',
    initialState: {
        stageList: null,
        isLoading: false,
        error: false,
        topicList: [],
        topicDetailList:[]
    },  
    reducers: {
        setStageSuccess: (state,action) => {
            state.stageList = action.payload;
        },
        startLoading(state) {
            state.isLoading = true;
        },
        hasError(state) {
            state.isLoading = false;
            state.error = true;
        },
        setTopicSuccess(state, {payload}) {
            state.isLoading = false;
            state.topicList = payload;
        },
        setTopicDetailSuccess(state, {payload}) {
            state.isLoading = false;
            state.topicDetailList = payload;
        },
    },
});

export default scoreCardSlice.reducer;

export const { setStageSuccess, startLoading, hasError, setTopicSuccess, setTopicDetailSuccess } = scoreCardSlice.actions;

export const getListStage = () => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());
            const response = await httpClient.get(server.SCORECARD_STATE);
            if (response.status === 200) {
                dispatch(setStageSuccess(response.data));
            }
        } catch (error) {
            dispatch(hasError(error));
        }
    };
};

export const getListTopics = (id_project, id_stage) => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());
            const response = await httpClient.get(server.SCORECARD_TOPIC + `?projectId=${id_project}&stageId=${id_stage}`);
            if (response.status === 200) {
                dispatch(setTopicSuccess(response.data.result));
            }
        } catch (error) {
            dispatch(hasError(error));
        }
    };
};
export const getListTopicsDetail = (id_project, id_topic, id_stage) => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());
            const response = await httpClient.get(server.SCORECARD_TOPIC_DETAIL + `?projectId=${id_project}&topicId=${id_topic}&stageId=${id_stage}`);
            if (response.status === 200) {
                dispatch(setTopicDetailSuccess(response.data.result));
            }
        } catch (error) {
            dispatch(hasError(error));
        }
    };
};

