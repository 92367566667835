import React from 'react';
import PropTypes from 'prop-types';
import * as useAuth from '../../redux/reducer/auth';
import { Navigate } from 'react-router-dom';
import { PATH_PAGE } from '../../routes/paths';
// ----------------------------------------------------------------------

AuthProtect.propTypes = {
    children: PropTypes.node
};

function AuthProtect({ children }) {

    if (!useAuth.isLoggedIn()) {
        return <Navigate to={PATH_PAGE.auth.login} />;
    }
    return <>{children}</>;
}

export default AuthProtect;
