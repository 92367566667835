import { createSlice } from '@reduxjs/toolkit';
import { changeSelectTap } from '../../redux/reducer/requrementSlice';
export const activeTabSlice = createSlice({
    name: 'activeTab',
    initialState: {
        activeTab: 0,
    },
    reducers: {
        setComplete: (state, action) => {
            state.activeTab = action.payload;
        },
    },
});

export default activeTabSlice.reducer;

export const { setComplete } = activeTabSlice.actions;

export const changeTab =
  ({ isTab }) =>
      async (dispatch) => {
          dispatch(setComplete(isTab));
          dispatch(changeSelectTap(0));
      };
