import React from 'react';
import './App.css';
import { Outlet } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import packageJson from '../package.json';

function App() {
    document.title = `DTGO MQDC V.${packageJson.version}`;
    return (
        <div className="App">
            <Outlet/>
        </div>
    );
}

export default App;
